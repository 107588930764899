import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/uireact/uireact/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Props } from 'docz';
import * as packageJson from '../package.json';
import Playground from '../../../src/Playground';
import { UiText } from '../src';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <h1 {...{
      "id": "uitext"
    }}>{`UiText`}</h1>
    <sup>
  <a href="https://github.com/inavac182/ui-react/blob/main/packages/text/" target="_blank">
    v{packageJson.version}
  </a>
    </sup>
    <blockquote>
      <p parentName="blockquote">{`Component to render texts`}</p>
    </blockquote>
    <h2 {...{
      "id": "installation"
    }}>{`Installation`}</h2>
    <blockquote>
      <p parentName="blockquote">{`npm i @uireact/foundation @uireact/text`}</p>
    </blockquote>
    <h2 {...{
      "id": "uitext-1"
    }}>{`UiText`}</h2>
    <Playground __position={1} __code={'<UiText>Some text</UiText>'} __scope={{
      props,
      DefaultLayout,
      Props,
      packageJson,
      Playground,
      UiText,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <UiText mdxType="UiText">Some text</UiText>
    </Playground>
    <p>{`By default the text is rendered inside a `}<inlineCode parentName="p">{`p`}</inlineCode>{` tag`}</p>
    <h2 {...{
      "id": "uitext-inline"
    }}>{`UiText inline`}</h2>
    <Playground __position={2} __code={'<UiText inline>Some text</UiText>'} __scope={{
      props,
      DefaultLayout,
      Props,
      packageJson,
      Playground,
      UiText,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <UiText inline mdxType="UiText">Some text</UiText>
    </Playground>
    <p>{`When using inline the text will be rendered in a `}<inlineCode parentName="p">{`Span`}</inlineCode>{` tag`}</p>
    <h2 {...{
      "id": "uitext-centered"
    }}>{`UiText centered`}</h2>
    <Playground __position={3} __code={'<UiText centered>Some text</UiText>'} __scope={{
      props,
      DefaultLayout,
      Props,
      packageJson,
      Playground,
      UiText,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <UiText centered mdxType="UiText">Some text</UiText>
    </Playground>
    <h2 {...{
      "id": "uitext-aligned-right"
    }}>{`UiText aligned right`}</h2>
    <Playground __position={4} __code={'<UiText align=\"right\">Some text</UiText>'} __scope={{
      props,
      DefaultLayout,
      Props,
      packageJson,
      Playground,
      UiText,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <UiText align="right" mdxType="UiText">Some text</UiText>
    </Playground>
    <h2 {...{
      "id": "uitext-wrapped"
    }}>{`UiText wrapped`}</h2>
    <Playground __position={5} __code={'<UiText wrap>\n  Long text that needs to be wrapped so it doesn\'t overflow to other areas of\n  the content where we wouldn\'t like it\n</UiText>'} __scope={{
      props,
      DefaultLayout,
      Props,
      packageJson,
      Playground,
      UiText,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <UiText wrap mdxType="UiText">
    Long text that needs to be wrapped so it doesn't overflow to other areas of the content where we wouldn't like it
  </UiText>
    </Playground>
    <h2 {...{
      "id": "uitext-with-color-category"
    }}>{`UiText with color category`}</h2>
    <Playground __position={6} __code={'<UiText category=\"error\">Some text </UiText>\n<UiText category=\"positive\" fontStyle=\"bold\">\n  Some text\n</UiText>\n<UiText category=\"negative\">Some text</UiText>'} __scope={{
      props,
      DefaultLayout,
      Props,
      packageJson,
      Playground,
      UiText,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <UiText category="error" mdxType="UiText">Some text </UiText>
  <UiText category="positive" fontStyle="bold" mdxType="UiText">
    Some text
  </UiText>
  <UiText category="negative" mdxType="UiText">Some text</UiText>
    </Playground>
    <h2 {...{
      "id": "uitext-with-inverse-coloration"
    }}>{`UiText with inverse coloration`}</h2>
    <Playground __position={7} __code={'<UiText inverseColoration>Some text </UiText>'} __scope={{
      props,
      DefaultLayout,
      Props,
      packageJson,
      Playground,
      UiText,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <UiText inverseColoration mdxType="UiText">Some text </UiText>
    </Playground>
    <h2 {...{
      "id": "uitext-with-default-coloration"
    }}>{`UiText with default coloration`}</h2>
    <Playground __position={8} __code={'<UiText coloration=\"dark\">Will always show DARK coloration</UiText>\n<UiText coloration=\"light\">Will always show LIGHT coloration</UiText>'} __scope={{
      props,
      DefaultLayout,
      Props,
      packageJson,
      Playground,
      UiText,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <UiText coloration="dark" mdxType="UiText">Will always show DARK coloration</UiText>
  <UiText coloration="light" mdxType="UiText">Will always show LIGHT coloration</UiText>
    </Playground>
    <h3 {...{
      "id": "props"
    }}>{`Props`}</h3>
    <Props of={UiText} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      